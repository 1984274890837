<template>
  <div class="store">
    <div class="title">
      <div @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <div>历史营收详情</div>
    </div>
    <div class="center">
      <div class="title-sum">
        <div class="sum-show">
          <div class="show-day">{{day}}</div>
        </div>
        <div class="sum-text">
          <p>{{ revenueObj.totalRevenue }}</p>
          <p>当日收入（元）</p>
        </div>
      </div>
      <div class="search">
        <div class="search-left">
          营收明细
        </div>
        <div class="search-right">
          时段：00：00—23：59
        </div>
      </div>
      <div class="center-pie">
        <div id="pie"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "store",
  data() {
    return {
      listData: [],
      idxShow: 0,
      shopId: "",
      revenueObj: {},
      date: "",
      day:'',
    };
  },
  mounted() {
    this.day = this.$route.query.day
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    this.date = this.$route.query.dateTime + "-" + this.$route.query.day;
    this.handleFirstData();
  },
  methods: {
    handleFirstData() {
      let _this = this;
      _this.$API
        .getRevenue({ shopId: this.shopId, date: _this.date })
        .then(function(res) {
          if (res.code == 200) {
            _this.revenueObj = res.data;
            _this.handlePieData();
          } else {
            Toast(res.msg);
          }
        });
    },
    // 环形图
    handlePieData() {
      //初始化
      let myChart = this.$echarts.init(document.getElementById("pie"));
      // 回执图表
      myChart.setOption({
        legend: {
          top: "bottom"
        },
        legend: { show: false },
        graphic: [
          //为环形图中间添加文字
          {
            type: "text",
            left: "center",
            top: "center",
            style: {
              textAlign: "center",
              fill: "#222222",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: "营收明显",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            labelLine: {
              show: true,
              normal:{  
                // length:5,  // 改变标示线的长度
                lineStyle: {
                  color: "#ECE9EC"  // 改变标示线的颜色
                }
              },
            },

            label: {
              normal: {
                position: "outside", // 设置标签向外
                // formatter: "{rect|} {b} \n\n {color|{c}} ", // 设置标签格式
                formatter: function(res){
                  let  data = ''
                  let  arr = [
                    {
                      name: '网费消费',
                    },
                    {
                      name: '商品消费',
                    }
                  ]
                  for(let i in arr) {
                    if(arr[i].name == res.name)  {
                      data = `{rect|} ${res.name}  \n\n {color|${res.value}} `
                    } else {
                      data = `{rects|} ${res.name} \n\n {color|${res.value}} `

                    }

                  }
                  return data
                 
                },
                rich: {
                  color: {
                    color: "#FE1C1C"
                  },
                  rect:{
                    height: 8,
                    width: 8,
                    backgroundColor: "#2765F2",
                    borderRadius: 3,
                  },
                  rects:{
                    height: 8,
                    width: 8,
                    backgroundColor: "#5CD08C",
                    borderRadius: 3,
                  }
                },

              }
            },
            data: [
              {
                value: this.revenueObj.networkFee,
                name: "网费消费",
                itemStyle: { color: "#5CD08C" }
              },
              {
                value: this.revenueObj.sale,
                name: "商品消费",
                itemStyle: { color: "#2765F2" }
              }
            ]
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.store {
  .title {
    display: flex;
    padding: 20px;
    align-items: center;
    i {
      font-size: 30px;
    }
    > div:nth-child(2) {
      flex: 5;
      text-align: center;
      font-size: 38px;
    }
  }
  .center {
    margin-top: 21px;
    .search {
      display: flex;
      padding-left: 30px;
      .search-left {
        font-size: 28px;
        font-weight: bold;
        color: #000000;
      }
      .search-right {
        flex: 6;
        padding-bottom: 30px;
        padding-right: 30px;
        color: #afb6cd;
        text-align: right;
        font-size: 24px;
        // border-bottom: 1px solid #e7e9ec;
        margin-top: 10px;
      }
    }
    .title-sum {
      height: 180px;
      background: url("~@/assets/img/list/listBg3.png");
      background-size: 100% 100%;
      color: #fff;
      display: flex;
      // align-items: center;
      justify-content: center;
      .sum-show {
        position: relative;
        margin-top: 10px;
        width: 119px;
        height: 119px;
        text-align: center;
        line-height: 119px;
        background: url("~@/assets/img/list/headerBg.png");
        background-size: 100% 100%;
      }
      .show-day {
        position: absolute;
        color: #6d8efa;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 46%;
        transform: translate(-50%, -50%);
        z-index: 33;
      }
      .sum-text {
        margin-left: 10px;
        margin-top: 20px;
        > p:nth-child(1) {
          font-size: 36px;
        }
        > p:nth-child(2) {
          margin-top: 10px;
          font-size: 22px;
        }
      }
    }
    .center-pie {
      // margin-top: 30px;
    }
    #pie {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
